body, canvas, div {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    /* cursor: pointer; */
}

canvas{
    cursor: url(./openhand.cur) 8 8, default;
}




html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

p {
    margin: 0;
}

body, dl, dd, h1, h2, h3, h4, h5, h6, p, span {
    margin: 0;
    padding: 0;
}

body {
    font: 0.12rem/1.5 tahoma, simsun, Microsoft YaHei, sans-serif;
    color: #fff;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
}

img {
    border: none;
    padding: 0;
    margin: 0;
    vertical-align: top;
}

em, i, b {
    font-style: normal;
}

ul, ol {
    list-style: none;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

input, select, option {
    outline: none;
}

body {
    margin: 0 auto;
    background: #000;
    font-family: 'Microsoft YaHei';
}

html {
    font-size: 50px;
}

.hid {
    display: none;
}

.show {
    display: block;
}

html, body {
    width: 100%;
    height: 100%;
}

body {
    -webkit-text-size-adjust: none;
    background: #000;
}

@keyframes wrapAnimation {
    from {
        transform: scale(0.8);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes loadtempAnimation {
    from {
        visibility: hidden;
        opacity: 0;
        transform: translateX(-50%) translateY(-20%);
    }
    to {
        visibility: visible;
        opacity: 1;
        transform: translateX(-50%) translateY(-50%);
    }
}

@keyframes loadBtnAnimation {
    from {
        visibility: hidden;
        opacity: 0;
        margin-top: 3.6vw;
    }
    to {
        visibility: visible;
        opacity: 1;
        margin-top: 3.2vw;
    }
}
.lodingBg{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transform-origin: 0 0;
    background: url('../images/loading-bg.png') no-repeat center center;
    background-size: cover;
    transform-origin: 50% 50% 0;
}
.loadingWrap{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width:8.85vw;
    height:1.04vw;
    border-radius:4px;
    padding:2px;
    border:2px solid rgba(152,133,96,1);
}

.start {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.34) 0%, rgba(0, 0, 0, 0.83) 100%);
    width: 100%;
    height: 100%;
    display:none;
    /* animation: wrapAnimation 1s ease-out; */
}
.circle{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 2000;
    width:81.5vw;
    height: 81.5vw;
    border-radius: 50%;
    border:1px dashed #988560;
}

#loading{
    height: 100%;
    display:block;
    width:0;
    background-color: #988560
}

.loadingTemp {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 30vw;
    visibility: hidden;
    overflow: hidden;
    transform: translateX(-50%) translateY(-50%);
    animation: loadtempAnimation 1.4s ease .5s forwards;
}

.startBtn {
    display: block;
    width: 8.54vw;
    height: 6.2vh;
    border: none;
    outline: none;
    visibility: hidden;
    background: url('../images/loadingBtn.png') no-repeat;
    background-size: 100% 100%;
    margin: auto;
    margin-top: 3.2vw;
    cursor: pointer;
    animation: loadBtnAnimation .3s ease 1.2s forwards;
}

.start img {
    width: 24.6vw;
}

.btn-back {
    position: absolute;
    width: 4.63%;
    height: 100vh;
    top: 0;
    left: 0;
    background: url('../images/left-bg.png') no-repeat;
    background-size: 100% 100%;
    /* background: rgba(1, 10, 20, 0.8); */

    display: none;
    z-index: 100;
}

.btn-back button {
    display: block;
    border: none;
    outline: none;
    margin-left: 1.8vw;
    /* margin: auto; */
    cursor: pointer;
}

.btn-back .go-office {
    width: 1.2vw;
    height: 1.4vw;
    margin-top: 5.55vh;
    margin-bottom: 6.5vh;
    background: url('../images/go-office.png') no-repeat;
    background-size: 100% 100%;
}

.btn-back .go-first {
    width: 1.56vw;
    height: 1.56vw;
    background: url('../images/go-back.png') no-repeat;
    background-size: 100% 100%;
}

.map {
    position: absolute;
    top: 0;
    left: 0;
    width: 5279px;
    height: 2612px;
    transform-origin: 0 0;
    transform: translateZ(0);
}

.map .map-main {
    width: 100%;
    height: 100%;
}

.dd {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1111;
    font-size: 40px;
}

.map-box {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.city-hero {
    /* display: none;
    z-index: 110;
    position: absolute;
    left: 0;
    top: 0;
    min-width: 5vw;
    background:rgba(1,1,1,0.73);
    box-shadow:0px 1px 0px 0px rgba(0,0,0,0.25);
    border-radius: 8px;
    padding: .6vw 0px .8vw .8vw; */
    
}

.city-hero-item{
    display: none;
    z-index: 110;
    position: absolute;
    left: 0;
    top: 0;
    min-width: 5vw;
    background:rgba(32,54,91,1);
    opacity:0.8;
    box-shadow:0px 10px 19px 0px rgba(0,0,0,0.5);
    border-radius: 8px;
    padding: .6vw 0px .8vw .8vw;
}

.city-other-hero a{
    position: absolute;
    display: block;
    background: url(../images/heroBg.png) no-repeat;
    background-size: 100%;
    z-index: 100;
    width: 3vw;
    height: 3.2vw;
}
.city-other-hero a img{
    width: 1.7vw;
    height: 1.7vw;
    margin: .6vw;
    border-radius:4px;
}
/*更多英雄*/

.more-hero {
    display: none;
    z-index: 110;
    position: absolute;
    left: 0;
    top: 0;
    width: 16vw;
    /* height: 5.4vw; */
    background:rgba(32,54,91,1);
    box-shadow:0px 10px 19px 0px rgba(0,0,0,0.5);
    border-radius:4px;
    opacity:0.8;
    padding: 0.6vw 0.6vw .8vw;
}
.more-hero h5{
    font-size: 16px;
    font-weight: 600;
    font-family: AlibabaPuHuiTiM;
    color:rgba(255,255,255,1);
    margin-bottom: 4px;
}
 .city-hero h5 {
    font-size: 0.83vw;
    font-family: AlibabaPuHuiTiM;
    color:rgba(255,255,255,1);
    margin-bottom: 8px;
}

.more-hero p {
    font-family: AlibabaPuHuiTiM;
    font-size: 12px;
    /* color:rgba(255,255,255,1); */
    color: #999;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0.65vw;
}

.more-hero .heroImgWrap img, .city-hero img {
    width: 1.7vw;
    height: 1.7vw;
    margin: 0 0.83vw 0 0;
    border-radius:4px;

}
.hero-point{
    width:8px;
    height:8px;
    border-radius: 50%;
    background-color: white;
    margin: auto;
    margin-top: 10px;
    opacity: 0.8;
}
.heroImgWrap{
    position: relative;
}

/*下面代码更加简洁*/
/* .triangle{
    position: absolute;
    left:-1.8vw;
    top: -0.2vw;
    width: 0;
    height: 0;
    z-index: 108;
    border: 0.52vw solid transparent;
    border-right-color: rgba(32,54,91,.8) ;
} */
.triangle{
    position: absolute;
    left:-2vw;
    top: -0.2vw;
   }
   .triangle:before,.triangle:after{
    position: absolute;
    content: '';
    border-top: 0.52vw transparent dashed;
    border-left: 0.52vw transparent dashed;
    border-bottom: 0.52vw transparent dashed;
    border-right: 0.52vw rgba(32,54,91,1) solid;
   }
   .triangle:before{
    border-right: 0.68vw rgba(255,255,255,1) solid;
   }
   .triangle:after{
    left: 0.156vw; /*覆盖并错开1px*/
    border-right: 0.52vw rgba(32,54,91,1) solid;
   }

.pop {
    display: none;
}

.right-box {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 101;
    width: 19vw;
    height: 100%;
    background: url(../images/right-bg.png) no-repeat;
    background-size: 100% 100%;
}

.right-box .close {
    position: absolute;
    width: 2vw;
    height: 2vw;
    left: -.9vw;
    top: 2.8vw;
    cursor: pointer;
    /* background: url(https://game.gtimg.cn/images/yxzj/act/a20180808map/img1.png) no-repeat 0 center; */
    /* background-size: 100% auto; */
}

.con-box {
    overflow: hidden;
    position: relative;
    height: 100%;
    margin: 0 2.6vw;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.con-box h5 {
    margin: 1.56vw 0;
    font-size: 1.56vw;
    color: rgba(251, 239, 216, 1);
}

.splitImg {
    width: 13.8vw;
}

.con-box-top {
    text-align: center;
    position: absolute;
    top: 0;
    left: 0.17rem;
}

.right-box .race {
    margin-top: 0.57rem;
    margin-bottom: 1.56vw;
}

.right-box .logo {
    width: 2.2vw;
    margin-right: .83vw;
}

.race .title {
    font-size: 1.56vw;
    color: rgba(251, 239, 216, 1);
    line-height: 0.3rem;
    font-weight: 600;
}

.heroIcon {
    width: 1.7vw;
    height: 1.7vw;
    margin: 0 0.83vw 0.4vw 0;
    border-radius:4px;
}

.pic {
    width: 100%;
    margin-bottom: 10px;
}
/* .info_imgs{
    height: 600px;
} */
.link {
    position: absolute;
    bottom: 3.125vw;
    left: 0rem;
    font-size: 0.94vw;
    color: rgba(251, 239, 216, 1);
    text-decoration: underline;
}

.link:hover {
    color: #409EFF;
    text-decoration: underline;
}

.textcon {
    font-size: 0.94vw;
    line-height: 1.3vw;
    color: rgba(251, 239, 216, 1);
    margin: 1.16vw 0 1.56vw 0;
    height: 10vw;
    text-align: left;
    letter-spacing:0;
    overflow: auto;
    font-family: AlibabaPuHuiTiM;
}

#moreHeroBtn {
    cursor: pointer;
}

/* 设置滚动条的样式 */

.textcon::-webkit-scrollbar, .cityInfo_content::-webkit-scrollbar{
    width: 0.04rem;
}

/* 滚动槽 */

.textcon::-webkit-scrollbar-track ,.cityInfo_content::-webkit-scrollbar-track{
    background: transparent;
    border-radius: 0.04rem;
}

/* 滚动条滑块 */

.textcon::-webkit-scrollbar-thumb ,.cityInfo_content::-webkit-scrollbar-thumb{
    background: rgba(95, 111, 137, 1);
    box-shadow: inset 0 0 0.01rem #101826;
    border-radius: 0.04rem;
}


.quality1{
    border:1px solid;
    border-image:linear-gradient(225deg, rgba(189,200,202,1), rgba(157,163,165,1)) 1 1;
    background:linear-gradient(224deg,rgba(132,150,153,1) 0%,rgba(168,187,190,1) 100%);
    box-shadow:0px 2px 4px 0px rgba(0,0,0,0.5);
}
.quality2{
    border:1px solid;
    border-image:linear-gradient(224deg, rgba(113,231,127,1), rgba(81,168,39,1)) 1 1;
    background:linear-gradient(222deg,rgba(50,136,44,1) 0%,rgba(111,179,78,1) 100%);
    box-shadow:0px 2px 4px 0px rgba(0,0,0,0.5);
}
.quality3{
    border:1px solid;
    border-image:linear-gradient(226deg, rgba(69,240,234,1), rgba(53,168,222,1)) 1 1;
    background:linear-gradient(228deg,rgba(79,126,178,1) 0%,rgba(101,155,197,1) 100%);
    box-shadow:0px 2px 4px 0px rgba(0,0,0,0.5);
}
.quality4{
    border:1px solid;
    border-image:linear-gradient(225deg, rgba(227,122,247,1), rgba(170,91,226,1)) 1 1;
    background:linear-gradient(225deg,rgba(156,35,255,1) 0%,rgba(197,58,255,1) 100%);
    box-shadow:0px 2px 4px 0px rgba(0,0,0,0.5);
}
.quality5{
    border:1px solid;
border-image:linear-gradient(225deg, rgba(230,203,98,1), rgba(185,133,77,1)) 1 1;
    background:linear-gradient(225deg,rgba(255,123,0,1) 0%,rgba(255,165,7,1) 100%);
    box-shadow:0px 2px 4px 0px rgba(0,0,0,0.5);
}


@media screen and (max-width: 1023px){
    .city-hero{
        min-width: 7vw;
    }
    .textcon {
        font-size: 0.2rem;
        line-height: 0.3rem;
        /* text-indent: 2em; */
        color: rgba(251, 239, 216, 1);
        margin: 1.16vw 0 1.56vw 0;
        height: 10vw;
        text-align: left;
        letter-spacing:0;
        overflow: auto;
        font-family: AlibabaPuHuiTiM;
    }
    .triangle{
        left:-2.1vw;
        top: -.9vw;
        z-index: 1111;
    }
    .right-box .race {
        margin-top: 5vh;
        margin-bottom: 1vw;
    }
    .race .title {
        line-height: 5vh;
    }
    .con-box h5 {
        margin: 1vw 0;
    }
    .link {
        width: 19vw;
        position: fixed;
        left: 81vw;
        bottom: 3vh;
        text-indent: 2.6vw;
    }
    .heroIcon {
        margin: 0 2vh 0 0;
    }
    .right-box{
        height: 100vh;
    }
    .right-box .close{
        left: -.5vw;
        top: 6vh;
    }
}

@media all and (min-width: 1000px) {
    .nav div::-webkit-scrollbar {
        height: 0.1rem;
    }
    /* 滚动槽 */
    .nav div::-webkit-scrollbar-track {
        background: #4d798d;
        border-radius: 0.04rem;
    }
    /* 滚动条滑块 */
    .nav div::-webkit-scrollbar-thumb {
        background: #5bb2d8;
        box-shadow: inset 0 0 0.01rem #101826;
        border-radius: 0.04rem;
    }
}

@media all and (orientation: portrait) {
    html, body {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
    }
    html::before {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background:rgba(28,33,40,1);
        z-index: 99999;
    }
    html::after {
        content: "为了更好的体验，请将手机横过来";
        position: absolute;
        top: 50%;
        left: 0;
        height: 30px;
        width: 100%;
        margin-top: 28px;
        z-index: 99999;
        text-align: center;
        font-size: 14px;
        color: #fff;
    }
    body::before {
        content: "";
        position: absolute;
        z-index: 99999;
        height: 200px;
        width: 100px;
        left: 50%;
        top: 50%;
        margin: -160px 0 0 -50px;
        color: #fff;
        background-image: url("../images/phone.png");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100px auto;
    }
}

.switch-wrap {
    position: fixed;
    display: inline-block;
    bottom: 5.55vh;
    left: 1vw;
    /* margin-top: 75.1vh;
    margin-left: 1vw; */
    width: 2.3vw;
    height: 1.35vw;
    border: 1px solid #DFDFDF;
    outline: none;
    border-radius: 0.755vw;
    box-sizing: border-box;
    background: #FFFFFF;
    cursor: pointer;
    transition: border-color .3s, background-color .3s;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
    vertical-align: middle;
}

.switch-wrap span {
    position: absolute;
    /* top: -1PX; */
    left: 0;
    transition: transform 0.3s;
    width: 1.2vw;
    height: 1.2vw;
    border-radius: 50%;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
    background-color: #fff;
}

.switch-wrap.active {
    border-color: rgba(28,129,231,1);
    background-color:rgba(28,129,231,1);
}

.switch-wrap.active span {
    transform: translateX(0.9vw);
}

.cityInfo_wrap{
    display: none;
    width:100%;
    height: 100%;
    z-index: 200;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(24, 36, 57, .9);
}


.cityInfo{
    width:1200px;
    margin:20px auto;
    height: 100%;
    position: relative;
    /* overflow-y:scroll; */
    /* padding :2.6vw 15%; */
  
    
}
.cityinfo_split{
    width:13.8%;
    margin: 20px 0 50px ;
}
.info_title{
    
font-size: 30px;
font-family: AlibabaPuHuiTiH;
color: #FFFFFF;
line-height: 42px;
}


.closeInfo{
    position: absolute;
    top:0;
    right:-20px;
    z-index: 300;
    width:2vw;
    cursor: pointer;
}

.cityInfo_content {
    width: 100%;
    overflow-y: scroll;
    /* padding-right: 20px; */
    padding-bottom: 20px;
    height: 72%;
    font-size: 16px;
    color: #FFFFFF;
}
.cityInfo_content img{
    max-width: 100%;

}
.cityInfo_content p{

    line-height: 22px;
}